<script>
export default {
  props: {
    name: String,
    attr: Object,
    option: String,
    checked: String,
    value: String,
    validate: { default: "" },
    disabled: Boolean,
    id: String,
  },
  data() {
    return {
      input: this.value,
    };
  },
  methods: {
    onClick() {
      this.$emit("input", this.option);
      setTimeout(
        () =>
          $(this.$el)
            .find("input")
            .valid(),
        10
      );
    },
  },
  watch: {
    input(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.input = v;
    },
  },
};
</script>

<template>
  <div
    class="custom-control custom-radio custom-control-inline"
    @click="onClick"
  >
    <input
      type="radio"
      :id="id"
      v-model="input"
      :name="name"
      :value="option"
      v-bind="attr"
      class="custom-control-input"
    />
    <label :class="'custom-control-label'" :for="id"><slot></slot></label>
  </div>
</template>
